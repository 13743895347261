import { useState } from 'react';
import { Form, useNavigation } from '@remix-run/react';
import { isValidUrl } from '~/lib/utils/client-utils';

export default function SearchUrlForm ({ error }) {
  const [inputCriteria, setInputCriteria] = useState({ valid: false, message: undefined });
  const transition = useNavigation();

  const onInputHandler = (e) => {
    const validUrl = isValidUrl(e.target.value);
    setInputCriteria({
      valid: validUrl,
      message: (!validUrl && e.target.value.length > 0) && 'Ogiltligt värde, ange en korrekt https-url till en av locals sajter'
    });
  };

  return (
    <div className="flex flex-col justify-center">
    <Form id="search-link-form" method="POST">
      <label className="pb-1.5 font-semibold flex text-app-black" htmlFor="link-url">
        Lägg till länkens URL
      </label>
        <div className="mb-2">
          <div>
            <input
              onInput={onInputHandler}
              className="md:col-span-2 peer input-search w-full"
              placeholder="Ex. https://www.sydsvenskan.se/livesport"
              name="q"
              id="link-url"
              type="search"
            />
            {inputCriteria.message && (
              <span className='w-full block text-app-red font-bold italic text-sm mt-2'>{inputCriteria.message}</span>
            )}
            <button
              className={`btn w-full mt-3.5 ${inputCriteria.valid ? 'btn-active' : 'btn-disabled'}`}
              id="search-link-submit"
              type="submit"
              name="form-name"
              value="search-link"
              disabled={!inputCriteria.valid}
              aria-label="Gå vidare till nästa steg"
            >
              {transition.state === 'submitting' || transition.state === 'loading' ? 'Vänta...' : 'Nästa' }
            </button>
          </div>
      </div>
    </Form>
    {error && (<div className="max-w">{error}</div>)}
    </div>
  );
}
