const { getAllConfig } = require('~/lib/utils/channel-util');
const getPaperOptions = () => {
  const selectionList = getAllConfig().map(entry => {
    const [channelId, channelConfig] = entry;
    const { name, appId } = channelConfig;
    return {
      id: appId,
      appName: name,
      appId,
      channelId,
    };
  });

  return selectionList;
};

module.exports = {
  getPaperOptions
};
