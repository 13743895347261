import { CalendarIcon } from '../icons/calendar';

export default function Scheduled () {
  return (
    <div className='push-history-badge bg-app-neutral-20'>
      <CalendarIcon />
      <span className='text-sm font-semibold text-app-neutral-60'>Schemalagd</span>
    </div>
  );
}
