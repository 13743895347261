export default function OpenCircleArrow ({
  width = 16,
  height = 17,
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 17" fill="none">
      <path d="M14 1.83337V5.83337H10" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.9999 9.16664C13.8583 10.5039 13.2716 11.7549 12.3339 12.7188C11.3962 13.6827 10.1619 14.3037 8.82902 14.482C7.49614 14.6604 6.14198 14.3858 4.98384 13.7024C3.8257 13.0189 2.93073 11.9662 2.44254 10.7132C1.95435 9.4602 1.90125 8.07951 2.29176 6.79269C2.68226 5.50588 3.49373 4.38756 4.59596 3.61718C5.69818 2.8468 7.02725 2.46903 8.3699 2.54449C9.71254 2.61995 10.9909 3.14427 11.9999 4.03331L13.9999 5.83331" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
