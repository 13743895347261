import { IconCheck } from '../icons/icon-check';

export default function Sent () {
  return (
    <div className='push-history-badge bg-app-success-light'>
      <div className='push-history-badge__sent-icon bg-app-success-accent'>
        <IconCheck width={12} height={12} className='' />
      </div>
      <span className='text-sm font-semibold text-app-success-accent'>Skickad</span>
    </div>
  );
}
