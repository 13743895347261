import { useLoaderData, useSearchParams } from '@remix-run/react';
import ExternalLink from '~/components/icons/external-link';
import { getChannelId } from '~/lib/utils/channel-util';
import ChannelIcon from '~/components/channel-icon';
import Sent from './badges/sent';
import Scheduled from './badges/scheduled';
import Sending from './badges/sending';
import { DisplayDate } from '~/lib/utils/client-utils';
import { Pen } from './icons/pen';
import ChevronUp from './icons/chevron-up';

export default function PushHistory () {
  const data = useLoaderData();
  const numPages = Math.ceil(data?.pushHistory?.totalCount / data?.pushHistory?.pageSize);
  const pages = Array.from({ length: numPages }, (_, i) => i + 1);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page') || 1);
  const currentPageWindow = pages.slice(currentPage - 1, currentPage + 2);
  if (!data?.pushHistory?.totalCount > 0) {
    return (
      <div className="no-notification mx-auto lg:w-5/6 h-28 flex justify-between">
        <p>Du har inga skickade pushnotiser i PushApp än.</p>
      </div>
    );
  }

  const pushedSegments = (pushNotification) => {
    return pushNotification.segments.map(segment => {
      return (
          <div key={`${pushNotification.id}-${segment.id}`} className="push-history__segment font-bold">
            <ChannelIcon className="h-5" channelId={getChannelId(segment.channelName)} />
            <p>{segment.title}</p>
          </div>
      );
    });
  };

  const scheduledSegmentPushes = data?.pushHistory?.items.filter(item => item.state === 'scheduled')?.sort((a, b) => new Date(b.scheduledAt).getTime() - new Date(a.scheduledAt).getTime());
  const nonSchedules = data?.pushHistory?.items?.filter(push => push.state !== 'scheduled').sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

  return (
    <div className="min-w-screen flex justify-center overflow-hidden">
      <div className="w-full lg:w-5/6">
        <div className="shadow-md rounded my-6">
          <table className="table-auto w-full uptosm:block">
            <thead className="uptosm:block">
              <tr className="bg-gray-200 text-gray-600 text-sm leading-normal uptosm:block">
                <th className="w-min-[280px] py-3 px-5 md:px-6 text-left font-medium uptosm:block">Pushnotis</th>
                <th className="py-3 px-6 text-left font-medium uptosm:hidden">Pushsegment</th>
                <th className="py-3 px-6 text-left font-medium uptosm:hidden">Status</th>
                <th className="w-min-[110px] py-3 px-6 text-left font-medium uptosm:hidden">Datum och tid</th>
                <th className="w-min-[140px] py-3 px-6 text-left font-medium uptosm:hidden">Abbe</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light uptosm:block uptosm:w-[90%] uptosm:mx-auto">
            {[...scheduledSegmentPushes, ...nonSchedules].map((pushNotification) => {
              return (
                <tr key={pushNotification.id} data-push-uuid={pushNotification.id} className="bg-app-neutral-10 border-b border-gray-200 hover:bg-gray-100  uptosm:grid uptosm:grid-cols-3 uptosm:gap-5 uptosm:py-8">
                  <td className="preview-history uptosm:block uptosm:col-span-3 uptosm:p-0">
                    <div className={'preview-item w-[230px]'}>
                      <span className="preview-item__time-ago text-[10px] tracking-[-0.02em] leading-3 self-center text-app-gray-text">43m sedan</span>
                      <h3 className="preview-item__title">{pushNotification.title}</h3>
                      <p className='preview-item__text text-xs tracking-tighter'>{pushNotification.body}</p>
                      { pushNotification.image_id && <img alt="" className="justify-self-end" src={`${ENV.BA_ORIGIN}/ba/${pushNotification.image_id}`} />}
                      <ChannelIcon className="preview-item__channel-icon" channelId={getChannelId(pushNotification.segments[0]?.channelName || data.appIds[0])} />
                    </div>
                  </td>
                  <td className="py-3 px-0 sm:px-6 text-center uptosm:block uptosm:col-span-3">
                    <div className="push-history__segments uptosm:flex uptosm:flex-wrap">
                      {pushedSegments(pushNotification)}
                    </div>
                  </td>
                  <td className="uptosm:block uptosm:self-center">
                    {pushNotification.state === 'scheduled' ? <Scheduled /> : pushNotification.state === 'delivered' ? <Sent /> : <Sending />}
                  </td>
                  <td className="w-40 py-3 px-0 sm:px-6 text-left uptosm:block uptosm:self-center uptosm:justify-self-center uptosm:text-center">
                    <DisplayDate utcString={pushNotification.state === 'scheduled' ? pushNotification.scheduledAt : pushNotification.updated_at} />
                  </td>
                  { pushNotification.abbe_id &&
                    <td className="py-3 px-0 sm:px-6 whitespace-nowrap uptosm:block">
                      {pushNotification.state === 'scheduled' && <a className="font-medium" href={`/notification/new/${pushNotification.abbe_id}`}>
                        <div className="text-base mx-auto flex flex-row items-center justify-evenly border-2 rounded-3xl w-28 text-app-purple border-[#E0C8FF] py-1.5 px-2.5 mb-2">
                          <Pen />
                          Redigera
                        </div>
                      </a>}
                      <a className="font-medium" href={`${ENV.ABBE_ORIGIN}/rewriter/articles/${pushNotification.abbe_id}`} target="_blank" rel="noreferrer">
                        <div className="text-base pr-0.5 mx-auto abbe-btn">
                          Öppna
                          <ExternalLink />
                        </div>
                      </a>
                    </td> }
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
        <div className='flex'>
          {currentPage > 1 && <button className='border border-solid border-app-gray rounded' onClick={() => {
            setSearchParams((prev) => { prev.set('page', Number(prev.get('page') || 1) - 1); return prev; }, { preventScrollReset: true });
          }}><ChevronUp className='w-10 h-10 rotate-[270deg]' /></button>}
          {currentPageWindow.map((page, i) => <button key={i} onClick={() => {
            setSearchParams((prev) => { prev.set('page', currentPage + i); return prev; }, { preventScrollReset: true });
          }} className={`ml-2 border border-solid border-app-gray rounded w-10 h-10 ${page === currentPage && 'underline font-bold'}`}>{page}</button>)}
          {currentPage !== numPages && <button className='ml-2 border border-solid border-app-gray rounded w-10 h-10' onClick={() => {
            setSearchParams((prev) => { prev.set('page', numPages); return prev; }, { preventScrollReset: true });
          }}>...</button>}
          {currentPage < numPages && <button className='ml-2 border border-solid border-app-gray rounded' onClick={() => {
            setSearchParams((prev) => { prev.set('page', Number(prev.get('page') || 1) + 1); return prev; }, { preventScrollReset: true });
          }}><ChevronUp className='w-10 h-10 rotate-[90deg]' /></button>}
        </div>
      </div>
    </div>
  );
}
