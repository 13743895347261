import SearchForm from '../components/search-form';
import SearchLinkForm from '~/components/search-link-form';
import { useEffect, useState } from 'react';
import {
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
  useRevalidator,
} from '@remix-run/react';
import { isValidUUID, isValidUrl } from '~/lib/utils/validation.server';
import { getSubject, getLink } from '../lib/data/form-data.server';
import { redirect, json } from '@remix-run/node';
import Error from '~/components/errorHandling/error';
import db from '~/lib/data/db/db.server';
import Accordion from '~/components/accordion';
import PushOverview from '~/components/push-overview';

const UNDELIVERED_CHECK_INTERVAL = 2000;

export default function Index ({ error, initialType = 'search-article' }) {
  // eslint-disable-next-line no-template-curly-in-string, no-script-url
  const bookmarkJS = "javascript:(function(){const pushAppLink='https://push-app-prod-r7llp462wa-ew.a.run.app'; const hostname=window.location.hostname;if(hostname==='prod.abbe.bonniernews.se'){window.open(`${pushAppLink}/notification/new/${window.location.href.split('/').pop()}`)}else{const uuid=(document.querySelector('article')).getAttribute('data-uuid');window.open(`${pushAppLink}/notification/new/${uuid}`)}}())";
  const bookmarkLink = `<a href="${bookmarkJS}"><strong>Dra mig till bokmärkesfältet 🔖</strong></a>`;

  const revalidator = useRevalidator();
  const data = useLoaderData();
  const { pushHistory = {} } = data || {};
  const { items = [] } = pushHistory;
  const undeliveredNotifications = items.filter(notification => notification.state !== 'delivered');
  useEffect(() => {
    if (undeliveredNotifications.length === 0) {
      return;
    }
    const intervalId = setInterval(() => {
      revalidator.revalidate();
    }, UNDELIVERED_CHECK_INTERVAL);
    return () => clearInterval(intervalId);
  }, [undeliveredNotifications, revalidator]);

  const [activeType, setType] = useState(initialType);
  const isArticle = activeType === 'search-article';
  const isLink = activeType === 'search-link';

  const tabColor = (active) => active ? 'text-white bg-app-purple' : 'bg-white text-app-purple';
  return (
    <>
      <div id="search-type-toggle" className="w-[90%] max-w-[550px] mx-auto mt-10 flex">
        <button
          onClick={() => setType('search-article')}
          className={`px-4 py-2 font-normal text-base rounded-t-[0.25rem] ${tabColor(isArticle)}`}
        >
          Artikel
        </button>
        <button
          onClick={() => setType('search-link')}
          className={`px-4 py-2 ml-2 font-normal text-base rounded-t-[0.25rem] ${tabColor(isLink)}`}
        >
          Länk
        </button>
      </div>
      <div className="bg-[#eef4ff] border-t-2 border-app-purple">
        <div className={`push-article-search flex items-center flex-col mx-auto pt-16 w-[90%] max-w-[550px] lg:min-h-[550px] ${isArticle ? '' : 'hidden'}`}>
          <h1 className="text-[2rem] lg:text-5xl font-extrabold mb-4 self-start">Artikel-push</h1>
          <p className="text-sm text-app-gray-text self-start mb-5">Pusha en enskild artikel på en eller flera titlar.</p>
          <div className="w-full">
              <div className="w-full mb-5">
                <SearchForm error={error?.props.page === 'search-article' && error}></SearchForm>
                <Accordion className="pt-12" title="Hur hämtar jag artikelns UUID?*">
                  <ol className="pl-5 text-sm list-decimal">
                    <li>Klicka in på en artikel i Abbe</li>
                    <li>Kopiera numret i URLn. Exempel: <strong>”9282b1f9-82d5-480f-bcd6-afe9c3e9ed77”</strong></li>
                    <li>Klistra in i fältet ovan.</li>
                  </ol>
                </Accordion>
                <p className="list-item text-sm text-app-gray-text ml-4">
                  <strong>Bokmärke</strong> för att skapa notis direkt från Abbe eller via artikel på sajt <br/> 👉 <span className="underline text-app-purple" dangerouslySetInnerHTML={{ __html: bookmarkLink }}></span>
                </p>
                <p className="list-item text-sm text-app-gray-text ml-4">
                  Besök artikel i fråga på sajt, klicka sedan på bokmärket.
                </p>
                <p className="list-item text-sm text-app-gray-text ml-4">
                  Alternativt leta upp artikeln i abbe och klicka på bokmärket.
                </p>
              </div>
          </div>
        </div>
        <div className={`push-link-input flex items-center flex-col mx-auto pt-16 w-[90%] max-w-[550px] lg:min-h-[550px] ${isLink ? '' : 'hidden'}`}>
          <h1 className="text-[2rem] lg:text-5xl font-extrabold mb-4 self-start">Länk-push</h1>
          <p className="text-sm text-app-gray-text self-start mb-5">Pusha annat än artiklar till en enskild titel, tex. en länk till all livesport eller eller till ett erbjudande.</p>
          <div className="w-full">
              <div className="w-full mb-5">
                <SearchLinkForm error={error?.props.page === 'search-link' && error}></SearchLinkForm>
              </div>
          </div>
        </div>
      </div>
      <PushOverview />
    </>
  );
}

export function ErrorBoundary () {
  const caught = useRouteError();

  if (isRouteErrorResponse(caught)) {
    return (
    <Index
      error={ <Error
        page={caught?.data?.type}
        role="alert"
        title="Error"
        message={caught?.data.message}
        status={caught?.status}
        classes="peer-focus:hidden"
      />}
      initialType={caught?.data?.type}
    />
    );
  }

  return (
    <div>
      <h1>Uh oh ...</h1>
      <p>Something went wrong.</p>
      <pre>Unknown Error</pre>
    </div>
  );
}

export async function loader ({ request }) {
  const url = new URL(request.url);
  const search = new URLSearchParams(url.search);
  const appIds = search.get('appIds')?.split(',');
  const page = search.get('page') || 1;
  if (appIds) {
    const pushHistory = await db.pushNotifications.get(appIds, { page });
    return {
      appIds,
      pushHistory
    };
  }

  return null;
}

export async function action ({ request }) {
  const formData = await request.formData();

  const name = formData.get('form-name');
  let message;
  const q = formData.get('q');
  if (!q) return null;

  try {
    switch (name) {
      case 'search-article': {
        if (isValidUUID(q)) {
          await getSubject('article', q);
          return redirect(`/notification/new/${q}`);
        }
        message = 'Invalid id, the id must be formated like: ccaecc4d-f259-4624-b27b-7bf98b800793';
        break;
      }
      case 'search-link': {
        if (isValidUrl(q)) {
          await getLink(q);
          return redirect(`/notification/new-link/${encodeURIComponent(q)}`);
        }
        message = 'Invalid url, the url must be formated like: https://www.sydsvenskan.se/livesport';
      }
    }
  } catch (err) {
    throw json({ message: err.message, type: name }, { status: err.cause });
  }

  if (message) {
    throw json(
      {
        message,
      },
      { status: 400 }
    );
  }
}
