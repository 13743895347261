import ChannelIcon from '~/components/channel-icon';

import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from '@remix-run/react';
import { getPaperOptions } from '~/lib/utils/paper-selector-list';

export default function PaperSelector () {
  const selectList = getPaperOptions();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedPaperAppIds = searchParams.get('appIds')?.split(',') || [];

  const handleChange = (appIds) => {
    setSearchParams((prev) => {
      if (appIds.length) {
        prev.set('appIds', appIds.join(','));
      } else {
        prev.delete('appIds');
      }
      return prev;
    }, {
      preventScrollReset: true,
    });
  };

  return (
    <Listbox value={selectedPaperAppIds} onChange={handleChange} multiple>
      {({ open }) => (
        <div className="relative mb-9 w-64">
          <Listbox.Button className="paper-selector">
            Välj titlar...
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="paper-selector__list">
              {selectList.map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) => `flex items-center relative cursor-default select-none py-2 pl-3 pr-9 ${active ? 'text-white bg-indigo-600' : 'text-gray-900'}`}
                  value={item.appId}
                >
                  <input className="mr-3" type="checkbox" checked={selectedPaperAppIds.includes(item.appId)} readOnly />
                  { item.channelId && <ChannelIcon channelId={item.channelId}/>}
                  <span className={'ml-3 block truncate'}>{ item.appName }</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
