import OpenCircleArrow from '../icons/open-circle-arrow';

export default function Sending () {
  return (
    <div className='push-history-badge bg-app-neutral-20'>
      <OpenCircleArrow />
      <span className='text-sm font-semibold text-app-neutral-60'>Skickar</span>
    </div>
  );
}
